<template>
	<div class="join-us">
		<div class="join-us-top">
			<img :src="bannerImg" style="width: 100%" />
			<div class="join-us-content">
				Go all out to do everything as a team.
				<div>趴趴教育</div>
			</div>
		</div>
		<div class="join-us-intro">
			趴趴教育是知名美股上市互联网公司控股子公司，作为一家成立于2014年的互联网教育公司，大部分成员毕业于帝国理工学院、纽约大学、伦敦政治经济学院、曼彻斯特大学、复旦大学、上海财经大学等名校。趴趴教育致力于为所有的年轻人定制全面的英语培训及出国留学等一站式服务，至今已发展为在留学生中享有好口碑、高推荐率的优质语培机构。
			目前公司已开设雅思、托福、GMAT、GRE、PTE等的学习课程产品以及英国、澳洲、新西兰院校的留学申请服务。趴趴成立以来，累计服务了几十万学习用户，在他们的留学路上添砖加瓦。迄今为止，趴趴作为国内较早扎根于互联网教育的品牌之一，一直以来广受学员好评。
			发展历程：
			<p>2014年【品牌诞生】</p>
			<p>
				趴趴教育正式成立，首创雅思口语备考智能选题系统，同时电商平台产品售卖好评率达99%。
			</p>
			<p>2015年【服务升级】</p>
			<p>雅思线上1V1课程正式上线，助教、答疑全方面配套服务提升。</p>
			<p>2016年【火速成长】</p>
			<p>雅思班课、托福、GMAT、GRE课程，出国留学申请服务全面上线。</p>
			<p>2017年【业务拓展】</p>
			<p>
				公司进一步互联网化，开发出备受好评的趴趴教育app，方便更多学生移动学习。
			</p>
			<p>2018~2020年【全面发展】</p>
			<p>
				升级服务体系，语培项目、出国留学申请项目全面发展。同时开发多个考试线上练习系统，更加满足学生学习需求。
			</p>
			<p>2021年【锐意进取】</p>
			<p>战略新布局：新增重庆分部。</p>
			<div
				v-if="!isShowAll"
				class=""
				style="color: #29d087; cursor: pointer"
				@click="hadnelShowAll"
			>
				查看更多
			</div>
			<div class="" v-else>
				公司实行扁平化管理，为员工打造价值最大化、薪酬极具市场竞争力的工作平台。在趴趴工作除了高薪你还可以收获：
				1、拓展人脉：公司同事非常优秀~大部分成员毕业于海内外名校!
        2、上海总部位于中心城区普陀区长风商圈，交通便利；武汉分部位于光谷商业街，5A级写字楼配置；重庆分部位于解放碑核心商圈，威斯汀5A级写字楼配置。
        3、年休假+五险一金+餐补+节日/纪念日福利+各类团建活动，你要的仪式感我们都有！
				4、入职即get颇具商业价值的岗前培训，提供暴风式成长的平台！
				欢迎更多热爱互联网教育行业的小伙伴加入我们~
				<div
					style="color: #29d087; cursor: pointer"
					@click="hadnelShowAll"
				>
					收起
				</div>
			</div>
		</div>
		<div v-if="isShow" class="join-us-intro-img-wrap">
			<div class="join-us-intro-img-left">
				<img :src="team1" />
			</div>
			<div class="join-us-intro-img-right">
				<div class="join-us-intro-img-right-top">
					<img :src="checkOneImg" />
				</div>
				<div
					class="join-us-intro-img-right-bottom join-us-check-more-wrap"
				>
					<img :src="rightTwo" />
					<div class="join-us-check-more" @click="checkMore">
						查看更多>
					</div>
				</div>
			</div>
		</div>
		<!--点击查看更多-->
		<div v-else class="join-us-intro-img-more-wrap">
			<div>
				<img :src="team1" />
				<div @click="comeBack">返回</div>
			</div>
			<div>
				<img :src="rightOneImg" />
			</div>
			<div>
				<img :src="rightTwo" />
			</div>
			<div>
				<img :src="checkOneImg" />
			</div>
			<div>
				<img :src="checkThreeImg" />
			</div>
			<div>
				<img :src="checkTwoImg" />
			</div>
			<div>
				<img :src="checkFourImg" />
			</div>
			<div>
				<img :src="checkFiveImg" />
			</div>
		</div>
		<div class="join-us-superiority-wrap">
			<div class="join-us-superiority-title">
				趴趴的薪酬与福利 颇具市场竞争优势
			</div>
			<div class="join-us-superiority-desc">
				我们坚信优厚的薪酬与福利，是你追梦路上的坚实后盾
			</div>
			<div class="join-us-superiority-grids">
				<div v-for="(item, index) in superiorityList" :key="index">
					<div>
						<div class="join-us-superiority-grid-img">
							<img :src="item.img" />
						</div>
						<div class="join-us-superiority-grid-cover"></div>
						<div class="join-us-superiority-grid-cover-content">
							{{ item.cover }}
						</div>
					</div>
					<div class="join-us-superiority-grid-bottom">
						<div v-html="item.desc"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="join-us-info-wrap">
			<div class="join-us-info-title">未来无限 等你加入</div>
			<div class="join-us-info-desc1">
				人性化的培养模式与广阔的发挥空间，从不缺少让你绽放光芒的舞台，无限可能，等你探索。
			</div>
			<div class="join-us-info-desc2">
			</div>
			<div class="join-us-info-desc3">
				如想了解更多招聘信息，可扫描以下二维码添加HR小姐姐微信（备注官网~），或者联系：hr@papaenglish.cn，进一步沟通哟
			</div>
			<div class="join-us-info-weixin">
				<img :src="weixinImg" />
			</div>
		</div>
		<FloatBar category="ielts"></FloatBar>
    <FloatAds></FloatAds>

	</div>
</template>

<script>
import store from '@/store';
import FloatBar from '@/components/FloatBar/FloatBar.vue';
import FloatAds from '@/components/FloatAds/FloatAds.vue'


export default {
	components: {
		FloatBar,
    FloatAds
	},
	data() {
		return {
			isShowAll: false,
			isShow: true,
			picHead: 'https://tcdn.papaen.com/assets/image/web/',
			superiorityList: [
				{
					cover: '薪酬',
					desc: '用薪酬认可价值：高于市场水平的薪酬、高频率调薪机会',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-one.png',
				},
				{
					cover: '福利',
					desc: '走心的福利体系：年休假+五险一金+餐补+节日/纪念日福利+各类团建活动等\n',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-two.png',
				},
				{
					cover: '发展',
					desc:
						'畅通的晋升通道，能力=机会，短时间内获得暴风成长<br/>' +
						'公司关注员工发展，提供内外部培训机会和培训补贴\n' +
						'                        ',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-three.png',
				},
				{
					cover: '环境',
					desc:
         '便利的办公地理位置，舒适的工作环境：\n' +
            '1、上海总部：中心城区普陀区长风商圈高档写字楼\n' +
            '2、武汉分部：光谷商业街5A写字楼\n' +
            '3、重庆分部：解放碑5A写字楼',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-four.png',
				},
				{
					cover: '关怀',
					desc: '免费体检，让你对自己的身体全面了解，健康生活',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-five.png',
				},
				{
					cover: '团队',
					desc: '年轻优秀的团队，超多国内外名校佼佼者，期待优秀的你加入\n',
					img: 'https://tcdn.papaen.com/assets/image/web/join-us-six.png',
				},
			],
		};
	},
	mounted() {},
	activated() {},
	computed: {
		bannerImg() {
			return this.picHead + 'banner.png';
		},
		weixinImg() {
			return this.picHead + 'weixin.png';
		},
		team1() {
			return this.picHead + 'team1.png';
		},
		checkOneImg() {
			// return this.picHead+'check-one.jpg'
			return this.picHead + 'right-two.jpg';
		},
		rightOneImg() {
			return this.picHead + 'check-four.jpg';
		},
		rightTwo() {
			// return this.picHead+'right-two.jpg'
			return this.picHead + 'team2.jpg';
		},
		checkTwoImg() {
			return this.picHead + 'check-two.jpg';
		},
		checkThreeImg() {
			return this.picHead + 'check-one.jpg';
			// return this.picHead+'check-three.jpg'
		},
		checkFourImg() {
			return this.picHead + 'right-one.jpg';
		},
		checkFiveImg() {
			return this.picHead + 'check-three.jpg';
		},
		joinSix() {
			return this.picHead + 'join-us-six.png';
		},
	},
	methods: {
		checkMore() {
			this.isShow = false;
		},
		comeBack() {
			this.isShow = true;
		},
		coverMore() {
			this.checkoutMore = true;
		},
		coverNone() {
			this.checkoutMore = false;
		},
		hadnelShowAll() {
			this.isShowAll = !this.isShowAll;
		},
	},

	watch: {},
};
</script>

<style lang="less" scoped>
/*@import "../../assets/style/lib-base.less";*/
.join-us-intro-img-more-wrap {
	width: 1202px;
	margin: 0 auto;
	position: relative;
	height: auto;

	& > div:nth-child(4n) {
		margin-right: 0px;
	}

	& > div:nth-child(6) {
		margin-bottom: 74px;
	}

	& > div:hover {
		& > div {
			display: block;
		}
	}

	& > div {
		width: 280px;
		display: inline-block;
		max-height: 189px;
		overflow: hidden;
		margin-right: 27px;
		float: left;
		border-radius: 4px;
		margin-bottom: 22px;
		position: relative;

		& > img {
			width: 100%;
		}

		& > div {
			position: absolute;
			bottom: 0;
			height: 100%;
			width: 100%;
			background: #000000;
			opacity: 0.6;
			color: #ffffff;
			font-size: 18px;
			line-height: 180px;
			cursor: pointer;
			display: none;
		}
	}

	@media screen and (max-width: 1264px) {
		& > div {
			width: 230px;
			max-height: 157px;
		}
	}
}

@media screen and (max-width: 1264px) {
	.join-us-intro-img-more-wrap {
		width: 1002px;
	}
}

.join-us-info-wrap {
	& > .join-us-info-title {
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: 500;
		color: rgba(51, 51, 51, 1);
		line-height: 30px;
		margin-top: 80px;
		margin-bottom: 22px;
	}

	& > .join-us-info-desc1 {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #555555;
		line-height: 24px;
		margin-bottom: 63px;
	}

	& > .join-us-info-desc2 {
		color: #555555;
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 10px;
	}

	& > .join-us-info-desc3 {
		color: #555555;
		font-size: 16px;
		margin-bottom: 19px;
	}
}

.join-us-superiority-wrap {
	background: #fafafa;
	padding-top: 60px;
	clear: both;

	& > .join-us-superiority-title {
		font-size: 30px;
		font-family: PingFang SC;
	}

	& > .join-us-superiority-desc {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: rgba(153, 153, 153, 1);
		line-height: 40px;
		margin-bottom: 60px;
	}

	& > .join-us-superiority-grids {
		max-width: 1200px;
		margin: 0 auto;

		& > div:nth-child(5) {
			margin-bottom: 60px;
		}

		& > div:nth-child(3n) {
			margin-right: 0px;
		}

		& > div {
			vertical-align: top;
			margin-right: 40px;
			background: #fff;
			display: inline-block;
			box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.04);
			border-radius: 4px;
			max-width: 360px;
			margin-bottom: 40px;

			& > div {
				display: inline-block;
				position: relative;

				& > .join-us-superiority-grid-img {
					max-height: 128px;
					overflow: hidden;

					& > img {
						width: 360px;
					}

					@media screen and (max-width: 1264px) {
						& > img {
							width: 294px;
						}
					}
				}

				& > .join-us-superiority-grid-cover {
					position: absolute;
					background: #000;
					opacity: 0.4;
					width: 100%;
					height: 100%;
					left: 0;
					bottom: 0;
				}

				& > .join-us-superiority-grid-cover-content {
					font-size: 26px;
					font-weight: bold;
					font-family: PingFang SC;
					position: absolute;
					line-height: 128px;
					color: #fff;
					top: 0;
					width: 100%;
				}
			}

			& > .join-us-superiority-grid-bottom {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #555555;
				line-height: 24px;
				text-align: justify;
				height: 120px;
				margin: 0 24px;
				display: table;

				& > div {
					display: table-cell;
					vertical-align: middle;
					white-space: pre-line;
				}
			}
		}

		@media screen and (max-width: 1264px) {
			& > div {
				max-width: 294px;
			}
		}
	}
}

.join-us-top {
	position: relative;

	& > .join-us-content {
		font-size: 40px;
		font-family: Avanti;
		font-weight: bold;
		color: #ffffff;
		position: absolute;
		line-height: 40px;
		top: 46%;
		display: inline-block;
		width: 100%;
		left: 0;

		& > div {
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(255, 255, 255, 1);
			line-height: 28px;
			display: inline-block;
			width: 100%;
			left: 0;
		}
	}
}

.join-us-intro {
	white-space: pre-line;
	color: #333333;
	font-size: 16px;
	text-align: justify;
	line-height: 28px;
	font-family: PingFang SC;
	width: 1087px;
	margin: 58px auto;
	p {
		margin: 0;
	}
}

@media screen and (max-width: 1264px) {
	.join-us-intro {
		width: 887px;
	}
}

.join-us-intro-img-wrap {
	width: 1200px;
	margin: 0 auto 80px;
	display: flex;
	justify-content: space-between;
	position: relative;

	& > .join-us-intro-img-left {
		border-radius: 4px;
		display: inline-block;
		max-height: 400px;
		max-width: 800px;
		overflow: hidden;

		& > img {
			width: 100%;
			display: inline-block;
		}
	}

	@media screen and (max-width: 1264px) {
		& > .join-us-intro-img-left {
			max-width: 700px;
		}
	}

	& > .join-us-intro-img-right {
		display: inline-block;
		max-height: 400px;
		position: relative;
		max-width: 360px;

		& > .join-us-intro-img-right-top {
			border-radius: 4px;
			height: 180px;
			overflow: hidden;

			& > img {
				width: 100%;
				display: inline-block;
			}
		}

		& > .join-us-check-more-wrap {
			& > .join-us-check-more {
				display: none;
			}
		}

		& > .join-us-check-more-wrap:hover {
			& > .join-us-check-more {
				display: block;
			}
		}

		& > .join-us-intro-img-right-bottom {
			height: 180px;
			overflow: hidden;
			position: absolute;
			bottom: 0;
			border-radius: 4px;

			& > img {
				width: 100%;
				display: inline-block;
			}

			& > .join-us-check-more {
				position: absolute;
				bottom: 0;
				height: 100%;
				width: 100%;
				background: #000000;
				opacity: 0.6;
				color: #ffffff;
				font-size: 18px;
				line-height: 180px;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1264px) {
		& > .join-us-intro-img-right {
			max-width: 260px;
		}
	}
}

@media screen and (max-width: 1264px) {
	.join-us-intro-img-wrap {
		width: 1000px;
	}
}
</style>
